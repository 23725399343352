<template>
    <div class="box">
        <div class="page-box-layout">
            <div class="title animated fadeclear fadeInDown"><img src="@/assets/img/showstartRelease/title.png" width="643"/></div>
            <div class="logo">
                <img src="@/assets/img/showstartRelease/s-logo.png" width="176" class="animated fadeclear fadeInUp1"/>
                <img src="@/assets/img/showstartRelease/X.png" width="17" class="x animated fadeclear fadeInUp3"/>
                <img src="@/assets/img/showstartRelease/sr-logo.png" width="195" class="animated fadeclear fadeInUp5"/>
            </div>
            <div class="more">
                <a href="javascript:void(0);"  @click="$release()">了解更多</a>
                <img src="@/assets/img/showstartRelease/logos.png" width="626"/>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            // releaseWeb: `${process.env.VUE_APP_RELEASEWEB_URL}`
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/animate.min.css';
@import '@/assets/css/animate.scss';

.box{
    background: url('~@/assets/img/showstartRelease/banner.jpg') center center no-repeat;
    background-size: cover;
    margin: -20px;
    min-height: calc(100% + 40px);
    .page-box-layout{
        padding: 200px 0;
        .logo{
            margin:  80px 0 50px 0;
            .x{
                margin: 0 60px;
            }
        }
        .more{
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            flex-wrap: wrap;
            a{
                display: inline-block;
                background: #5914EE;
                border-radius: 26px;
                color: #fff;
                text-align: center;
                height: 52px;
                line-height: 52px;
                width: 206px;
                font-size: 14px;
            }
            img{
                margin-bottom: 10px;
            }
        }

        img{
            max-width: 100%;
        }
    }
}
</style>